import dynamic from 'next/dynamic';
import React from 'react';

const MapNoSSR = dynamic(() => import('../components/exploreMap/ExploreMap'), { ssr: false });

const explore = (): JSX.Element => {
  return <MapNoSSR />;
};

export default explore;
